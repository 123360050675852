<template>
	<div class="header">
		<div class="logo">
			<img src="../../assets/fLogo.png" alt="">
			<!-- <p style="margin-right: 20px; font-size: 18px;">JEKEE</p> -->
			<!-- <img src="../../assets/logo.png" alt="" class="logoImg" /> -->
			<!-- <p>工厂端</p> -->
		</div>
		<el-dropdown @command="handleCommand">
			<span class="el-dropdown-link">
				操作员({{name}})<i class="el-icon-arrow-down el-icon--right"></i>
			</span>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item command="out">退出登录</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</div>
</template>

<script>
export default {
	name: 'home',

	data: function () {
		return {
			form: '',
			name:''
		}

	},
	created() {
		console.log(localStorage.getItem('userinfo'))
		
		this.name = JSON.parse(localStorage.getItem('userinfo')).realname
		// document.querySelector('.containers').style.height = document.documentElement.clientHeight + 'px';
		// 		//   get不传参
		// 		getList() {
		//     getListAPI().then(res => console.log(res)).catch(err => console.log(err))
		//   },
		// 	//post传参
		//   postForm(formData) {
		//     let data = formData
		//     postFormAPI(data).then(res => console.log(res)).catch(err => console.log(err))
		//   },

		//   //async await同步调用
		//   async postForm(formData) {
		//     const postRes = await postFormAPI(formData)
		//     const putRes = await putSomeAPI({data: 'putTest'})
		//     const deleteRes = await deleteListAPI(formData.name)
		//     // 数据处理
		//     console.log(postRes);
		//     console.log(putRes);
		//     console.log(deleteRes);
		//   }
	},
	methods: {
		handleCommand(command) {
			if(command == 'out'){
				this.$http.post('/index/logout', {id:localStorage.getItem('id'),client_id:localStorage.getItem('clientId')}, (data) => {
                        // console.log(data)
                        if(data.code == 1){
							localStorage.removeItem('username');
					        localStorage.clear();
                            this.$router.push('/login')
                        }else{
                            this.$message.error(data.msg)
							localStorage.removeItem('username');
							localStorage.removeItem('userinfo');
					        localStorage.clear();
                            this.$router.push('/login')
                        }
                    })
			}
      }
	}
}
</script>

<style>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #2D4164;
	/* height: .75rem; */
	padding: 0 1rem;
	width: 1920px;
	height: 44px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
}

.logo {
	display: flex;
	align-items: center;
}

.logoImg {
	width: .375rem;
	height: .375rem;
	margin-right: .125rem;
}

.el-dropdown-link {
	cursor: pointer;
}</style>