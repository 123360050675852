<template>
    <div class="big">
        <div class="left">
            <div class="left-title">
                <input type="text" ref="inputGoods" v-model="goodsId" placeholder="请扫描或输入水洗唛编码">
                <button @click="searchOrder">查询</button>
            </div>
            <div class="left-content" v-if="goodsList.length != 0">
                <p class="title">订单编号:</p>
                <p class="num">{{ goodsInfo.number }}</p>
                <p class="title">
                    订单状态:
                    <span v-if="this.goodsInfo.orderStatus == 20">已揽收送货工厂中</span>
                    <span v-if="this.goodsInfo.orderStatus == 22">工厂已收货</span>
                    <span v-if="this.goodsInfo.orderStatus == 23">工厂清洗中</span>
                    <span v-if="this.goodsInfo.orderStatus == 24">清洗完成</span>
                    <span v-if="this.goodsInfo.orderStatus == 30">派送中</span>
                </p>
                <ul class="order-list ">
                    <li @click="checkGoods(index)" :class="checkIndex == index ? 'order-item order-item-add' : 'order-item'" v-for="(item,index) in goodsList" :key="index">
                        <p>商品ID:{{ item.id }}</p>
                        <p>商品名称:{{ item.title }}</p>
                    </li>
                </ul>
                <button class="order-btn">完成补录</button>
            </div>
            <div v-if="goodsList.length == 0">
                <el-empty description="暂无订单"></el-empty>
            </div>
        </div>
        <div class="right">
            <div class="right-tabs">
                <el-tabs v-model="activeName" :stretch="true" type="border-card">
                    <el-tab-pane label="分类" name="one">
                        <div class="sort-title">
                            <ul>
                                <li class="sort-item" @click="getType(index)" v-for="(item,index) in clothType">
                                    {{ item.name }}
                                </li>
                            </ul>
                        </div>
                        <ul class="cloth">
                            <li class="cloth-item" v-for="i in 50">
                                <img class="cloth-img" src="../../assets/logo.png" alt="">
                                <p>123</p>
                            </li>
                        </ul>
                    </el-tab-pane>
                    <el-tab-pane label="拍照" name="two"></el-tab-pane>
                    <el-tab-pane label="颜色" name="three"></el-tab-pane>
                    <el-tab-pane label="附件" name="four"></el-tab-pane>
                    <el-tab-pane label="瑕疵" name="five"></el-tab-pane>
                    <el-tab-pane label="洗涤风险" name="six"></el-tab-pane>
                    <el-tab-pane label="附件" name="seven"></el-tab-pane>
                </el-tabs>
            </div>
            <!-- <div>
                <button>打印水洗唛</button>
            </div> -->
            <div class="right-print">
                <canvas style="display: none;" ref="barcodeCanvas"></canvas>
                <div style="display: inline-block;">
                    <p>补录时间:{{ entryTime }}</p>
                    <p style="margin-left: 10px;">{{ goodsInfo.number }}</p>
                    <!-- <p>{{ goodsInfo.id }}</p> -->
                    <img style="margin-left: 10px;" :src="barcodeUrl" alt="">
                    <p style="margin-left: 40px;">{{ goodsInfo.id }}</p>
                </div>
                <div style="display: inline-block;">
                    <p>[{{ goodsList.length }}--{{ checkIndex + 1 }}]</p>
                    <p>洗护类别:{{ factoryInfo.goodsType }}</p>
                    <p>颜色:{{ factoryInfo.goodsColor }}</p>
                    <p>瑕疵:{{ factoryInfo.goodsFlaw }}</p>
                    <p>附件:{{ factoryInfo.goodsFiles }}</p>
                    <p>备注:{{ factoryInfo.goodsNotes }}</p>
                </div>
            </div>
            <div class="right-btn">
                <el-button type="primary">打印水洗唛</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'subGoodsAgain',
    components: {

    },
    props: {
        indexFlag: {
            type: String
        },
    },
    data() {
        return {
            goodsId: '',
            activeName: 'one',
            goodsList:[],
            orderNum:'',
            orderStatus:'',
            checkIndex:0,
            factoryInfo:{},
            clothType: [
                { name: '上装', value: 0 },
                { name: '下装', value: 1 },
                { name: '鞋类', value: 2 },
                { name: '家纺', value: 3 },
                { name: '其他', value: 4 },
                { name: '小件衣物', value: 5 }

            ],
            entryTime:'',
            goodsInfo:{},
            barcodeUrl:''
        }
    },
    watch: {
        indexFlag(newValue, oldValue) {
            if(newValue == 9){
                this.$refs.inputGoods.focus();
            }
        },
        immediate: true,
        deep: true
    },
    mounted() {
        this.getEntryTime();
        this.$nextTick(() => {
            window.addEventListener('keyup', (e) => {
                if (e.repeat) {
                    return;
                }
                if (e.keyCode == 13) {
                    this.searchOrder();
                }
            })
        })
    },
    updated(){
        // this.getEntryTime();
        if(this.goodsInfo){
            this.generateBarcode();
        }
    },
    computed: {

    },
    methods: {
        // 获取时间
        getEntryTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const day = now.getDate();
            const time = now.toLocaleTimeString();
            this.entryTime = `${year}-${month}-${day} ${time}`;
            console.log(this.entryTime);
            
        },
        // 查询
        searchOrder(){
            this.getEntryTime();
            let uid = JSON.parse(localStorage.getItem('userinfo')).id;
            let obj = {
                ogid: this.goodsId,
                id: uid
            };
            this.$http.post('/receiving/waterWashedLabelorderInfo',obj,(res) => {
                console.log(res.data);
                if(res.code == 1){
                    if(res.data.length == 0){
                        this.$message({
                            message: '暂无商品信息',
                            type: 'error'
                        });
                        this.goodsId = ''
                    }else{
                        this.goodsList = res.data;
                        this.goodsInfo = res.data[0];
                        // this.orderNum = res.data[0].number;
                        // this.orderStatus = res.data[0].orderStatus;
                        this.factoryInfo = JSON.parse(res.data[0].factory_use_info)
                        console.log(this.factoryInfo,'-----工厂录入信息');
                        this.goodsId = '';
                    }
                }
            })
        },
        // 选择商品列表
        checkGoods(index){
            this.checkIndex = index;
            this.factoryInfo = JSON.parse(this.goodsList[index].factory_use_info);
            this.goodsInfo = this.goodsList[index];
            this.getEntryTime();
        },
        generateBarcode() {
            const barcodeData = this.goodsInfo.id;
            const canvas = this.$refs.barcodeCanvas;
            if (canvas == undefined) {
                return;
            } else {
                JsBarcode(canvas, barcodeData, {
                    format: 'CODE128',
                    lineColor: '#000000',
                    width: 2,
                    height: 50,
                    displayValue: false,
                    margin: 0,
                    fontSize: 20
                });
                const dataUrl = canvas.toDataURL('image/png');
                this.barcodeUrl = dataUrl;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.big {
    display: flex;

    .left {
        width: 320px;
        height: 830px;
        background-color: #FFFFFF;
        padding: 20px;

        .left-title {
            display: flex;
            margin-bottom: 16px;

            input {
                width: 217px;
                height: 40px;
                border: 1px solid #DCDFE6;
                border-radius: 5px;
            }

            button {
                width: 71px;
                height: 40px;
                background-color: #3278FF;
                border: #FFFFFF;
                color: #FFFFFF;
                border-radius: 5px;
            }
        }

        .left-content {
            .title {
                color: #666666;
                font-size: 16px;

                span {
                    color: #3278FF;
                }
            }

            .num {
                color: #333333;
                font-size: 16px;
                margin-bottom: 16px;
            }

            .order-list {
                margin-top: 16px;

                .order-item {
                    box-sizing: border-box;
                    padding: 10px;
                    width: 280px;
                    height: 104px;
                    margin-bottom: 5px;

                    p {
                        color: #666666;
                        font-size: 18px;
                        margin-bottom: 16px;
                    }
                }
                .order-item-add{
                    border: 2px dotted #333333;
                }
            }

            .order-btn {
                position: fixed;
                top: 820px;
                width: 280px;
                height: 40px;
                font-size: 18px;
                color: #FFFFFF;
                border: #FFFFFF;
                background-color: #CCCCCC;
            }
        }
    }

    .right {
        width: 100%;
        height: 830px;
        background-color: #FFFFFF;
        .right-tabs {
            height: 640px;

            .sort-title {
                ul {
                    display: flex;

                    .sort-item {
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        margin-top: 16px;
                        width: 76px;
                        height: 48px;
                        text-align: center;
                        line-height: 48px;
                        background-color: #F5F5F5;
                        margin-right: 16px;
                        border-radius: 24px;
                        font-size: 18px;
                    }
                }
            }

            .cloth {
                height: 490px;
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                overflow: auto;
                .cloth-item {
                    width: 88px;
                    height: 168px;
                    margin-right: 8px;
                    margin-top: 16px;
                    border: 1px solid black;
                    background-color: #F0F0F0;
                    .cloth-img {
                        width: 88px;
                        height: 88px;
                    }
                }
            }
        }

        .right-print {
            height: 190px;
            width: 1200px;
        }
        .right-btn{
            position: absolute;
            left: 1600px;
            top: 640px;
        }
    }
}
</style>