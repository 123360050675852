<template>
    <div>
        <ul class="order_list" v-infinite-scroll="load" style="overflow:auto;margin-top: 0;"
            v-if="allStatus == 'receiving'">
            <li v-if="orderList.length > 0" v-for="(item, index) in orderList" :key="index"
                @click="intro(index, 'receiving', item)"
                :style="selected == index ? 'background:rgba(0,0,0,0.08);' : ''">
                <div class="order_l_t">
                    <p>订单编号：<span>{{ item.number }}</span><span v-if="item.urgent == 2"
                            style="background:red;color:#fff;font-size:14px;padding:0px 5px;border-radius: 4px;display: inline;">加急</span>
                    </p>
                    <div class="order_status_list" v-if="authid == '2'" @click="remove(index)">移除</div>
                </div>
                <div class="order_c">
                    <div class="order_right">
                        <div class="product">商品名称：<span>{{ item.title }}</span></div>
                        <div class="product">商品类别：<span>{{ item.type_title }}</span></div>
                        <div class="product">商品ID：<span>{{ item.id }}</span></div>
                        <div class="product" v-if="item.status == 12">商品状态：<span class="green">已收件</span></div>
                        <div class="product" v-if="item.status == 19">商品状态：<span class="blue">已收件</span></div>
                        <div class="product" v-if="item.status == 50">商品状态：<span class="red">已退款</span></div>
                        <div class="product" v-if="item.status == 52">商品状态：<span class="red">已退款(工厂端)</span></div>
                    </div>
                    <!-- <el-button @click="sendMsg" size="mini" type="danger">拒收</el-button> -->
                    <!-- <el-button @click="openDelete(item.id)" size="mini" type="danger">删除</el-button> -->
                </div>
            </li>
            <div v-if="orderList.length > 0 && isVIP == 1" style="display: flex; justify-content: space-between; font-size: 15px;">
                <div>泡泡余额:<span style="color: red;">{{ allPopNums }}</span></div>
                <div>消耗泡泡:<span style="color: red;">{{ goodsPopNums }}</span></div>
            </div>
            <el-button v-if="indexFlag == 2 && showBtn == '0' && orderList.length > 0" type="success"
            style="margin-left: 30%; margin-top: 20px;" @click="sendAdd">添加商品</el-button>
            <div v-if="isVIP == 1 && orderList.length == 0 && showAddbtn">
                <p>会员订单请添加商品</p>
                <el-button v-if="indexFlag == 2 && showBtn == '0'" type="success"
            style="margin-left: 30%; margin-top: 20px;" @click="sendAdd">添加商品</el-button>
            </div>
            <el-empty description="暂无订单" v-if="orderList.length == 0 && isVIP == ''"
                style="height:100%;display: flex;align-items: center;justify-content: center;"></el-empty>
            <el-button v-if="orderList.length > 0 && indexFlag == 2 && showBtn == '0' && printNum >= orderList.length"
                type="warning" style="margin-left: 30%;  margin-top: 20px;" @click="Unbind('解绑成功')">解绑订单</el-button>
            <!-- <el-button v-if="orderList.length > 0 && showBtn == '1'" type="warning" style="margin-left: 30%;"
                @click="Unbind('补录成功')">补录并保存</el-button> -->
        </ul>
        <ul class="order_list" v-infinite-scroll="load" style="overflow:auto;margin-top: 0;"
            v-if="allStatus == 'rejection'">
            <li v-if="orderList.length > 0" v-for="(item, index) in orderList" :key="index"
                @click="intro(index, 'rejection', item)"
                :style="selected == index ? 'background:rgba(0,0,0,0.08);' : ''">
                <div class="order_l_t">
                    <p>订单编号：<span>{{ item.number }}</span><span v-if="item.urgent == 2"
                            style="background:red;color:#fff;font-size:14px;padding:0px 5px;border-radius: 4px;display: inline;">加急</span>
                    </p>
                </div>
                <div class="order_c">
                    <div class="order_right">
                        <div class="product">商品名称：<span>{{ item.title }}</span></div>
                        <div class="product">商品类别：<span>{{ item.type_title }}</span></div>
                        <div class="product">商品状态：<span class="green">待处理</span></div>
                    </div>
                </div>
            </li>
            <el-empty description="暂无订单" v-if="orderList.length == 0"
                style="height:100%;display: flex;align-items: center;justify-content: center;"></el-empty>
        </ul>
        <ul class="order_list" v-infinite-scroll="load" style="overflow:auto;margin-top: 0;"
            v-if="allStatus == 'inspection'">
            <li v-if="orderList.length > 0" v-for="(item, index) in orderList" :key="index"
                @click="intro(index, 'inspection')" :style="selected == index ? 'background:rgba(0,0,0,0.08);' : ''">
                <div class="order_l_t">
                    <p>订单编号：<span>{{ item.number }}</span><span v-if="item.urgent == 2"
                            style="background:red;color:#fff;font-size:14px;padding:0px 5px;border-radius: 4px;display: inline;">加急</span>
                    </p>
                </div>
                <div class="order_c">
                    <div class="order_right">
                        <div class="product">商品名称：<span>{{ item.title }}</span></div>
                        <div class="product">商品类别：<span>{{ item.type_title }}</span></div>
                        <div class="product" v-if="item.status == 20 && !zhijian">商品状态：<span class="green">待质检</span>
                        </div>
                        <div class="product" v-if="zhijian && (item.status !== 50 || item.status == 52)">商品状态：<span
                                class="blue">已质检</span></div>
                        <div class="product" v-if="item.status == 50">商品状态：<span class="red">已退款</span></div>
                        <div class="product" v-if="item.status == 52">商品状态：<span class="red">已退款(工厂端)</span></div>
                    </div>
                </div>
            </li>
            <el-empty description="暂无订单" v-if="orderList.length == 0"
                style="height:100%;display: flex;align-items: center;justify-content: center;"></el-empty>
        </ul>
        <ul class="order_list" v-infinite-scroll="load" style="overflow:auto;margin-top: 0;"
            v-if="allStatus == 'allOrder'">
            <li v-if="orderList.length > 0" v-for="(item, index) in orderList" :key="index"
                @click="intro(index, 'allOrder', item)"
                :style="selected == index ? 'background:rgba(0,0,0,0.08);' : ''">
                <div class="order_l_t">
                    <p>订单编号：<span>{{ item.number }}</span><span v-if="item.urgent == 2"
                            style="background:red;color:#fff;font-size:14px;padding:0px 5px;border-radius: 4px;display: inline;">加急</span>
                    </p>
                </div>
                <div class="order_c">
                    <div class="order_right">
                        <div class="product">商品名称：<span>{{ item.title }}</span></div>
                        <div class="product">商品类别：<span>{{ item.type_title }}</span></div>
                        <div class="product">商品状态：<span class="blue" v-if="item.status == 19">已收件</span><span
                                class="red" v-if="item.status == 31">拒收件</span>
                            <span class="green" v-if="item.status == 20">未质检</span>
                            <span class="green" v-if="item.status == 21">清洗完成</span>
                        </div>
                    </div>
                </div>
            </li>
            <div v-if="orderList.length == 0 && isVIP == 1">
                <p>此单为会员订单请添加商品</p>
                <el-button v-if="indexFlag == 2 && showBtn == '0'" type="success"
            style="margin-left: 30%; margin-top: 20px;" @click="sendAdd">添加商品</el-button>
            </div>
            <el-empty description="暂无订单" v-if="orderList.length == 0 && isVIP == ''"
                style="height:100%;display: flex;align-items: center;justify-content: center;"></el-empty>
        </ul>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    props: {
        allPopNums:{
            type:[String,Number]
        },
        goodsPopNums:{
            type:[String,Number]
        },
        allStatus: {
            type: String,
            default: 'receiving'
        },
        orderList: {
            type: [Array, Object],
            default() {
                return []
            }
        },
        selectedorder: {
            type: [Number, String],
            default: 0
        },
        zhijian: {
            type: Boolean,
            default: false
        },
        indexFlag: {
            type: String
        },
        showBtn: {
            type: String,
            default: '0'
        },
        printNum: {
            type: Number,
            default: 0
        },
        isVIP:{
            
        }
    },
    watch: {
        isVIP:{
            handler(newValue,oldValue){
                console.log(newValue,'4546546565465456',oldValue);                 
            }
        },
        selectedorder() {
            this.selected = this.selectedorder
        }
    },
    data: function () {
        return {
            receivingList: ['待收件', '拒收件', '可收件', '准收件'],
            rejectionList: ['已完成拒收', '待处理', '准收件'],
            ten: ['1', '1', '1', '1', '1', '1', '1', '1'],
            selected: this.selectedorder,
            authid: 0,
            flag: true,
            addGoodsFlag: true,
            showAddbtn:true
        }
    },
    created() {
        this.authid = JSON.parse(localStorage.getItem('userinfo')).authid
    },
    mounted() {
    },
    methods: {
        openDelete(goodsId) {
            if(this.orderList.length <= 1){
                this.$message({
                    type: 'info',
                    message: '只剩下一件商品了，请先添加再删除!'
                });
            }else{
                this.$confirm(`确定删除商品ID为${goodsId}的商品?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post('order/delGoods',{ogId:goodsId},(res => {
                        if(res.code == 1){
                            this.$emit('getOrderDel',true)
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }else{
                            this.$message({
                                type: 'info',
                                message: `删除失败!${res.msg}`
                            });
                        }
                    }))
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
        sendMsg() {
            this.$emit('sendMsg', this.flag)
        },
        sendAdd(){
            this.$emit('sendAdd',true);
        },
        // 点击解绑订单按钮回调
        Unbind(e) {
            console.log('解绑按钮被点击', e);
            let self = this;
            if (self.orderList.length === 0) {
                return;
            }
            if (e == '解绑成功') {
                var data = {
                    rfid: self.orderList[0].rfid,
                    ogid: this.orderList[0].id,
                    id: localStorage.getItem('id')
                }
                this.$http.post('/receiving/unbindput', data, (res) => {
                    console.log(res)
                    if (res.code == 1) {
                        self.showStatus = true
                        self.showText = e
                        setTimeout(function () {
                            self.showStatus = false
                        }, 3000)
                        this.$emit('refresh', 0)
                        this.$message({
                            type: 'success',
                            message: '解绑成功,请扫描其他商品'
                        })
                        this.printNum = '';
                        this.$emit('clearOrder',true);
                        this.showAddbtn = false;
                        this.$emit('orderIndex', 0);
                        
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                })
            } else if (e == '补录成功') {
                console.log('发请求推送补录订单到山库');
            }
            if (e == '质检合格') {
                if (this.orderid.images_qua.length == 0) {
                    this.$message({
                        type: 'error',
                        message: '请先上传质检图片'
                    })
                    return;
                }
                var data = {
                    image: this.orderid.images_qua,
                    ogid: this.orderid.id,
                    id: localStorage.getItem('id')
                }
                this.$http.post('/quality/editqualified', data, (res) => {
                    console.log(res)
                    if (res.code == 1) {
                        self.showStatus = true
                        self.showText = e
                        setTimeout(function () {
                            console.log(11111)
                            self.showStatus = false
                        }, 3000)
                        this.$emit('refresh', 0)
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                })
            }
        },
        intro(e, a, item) {
            this.selected = e
            this.$emit('orderStatus', e);
            this.$emit('orderCategory', a);
            this.orderIndex = e;
            this.$emit('orderIndex', this.orderIndex);

            // if (JSON.parse(localStorage.getItem('userinfo')).authid == '1' && item.status === 12) {
            //     $.getJSON('http://127.0.0.1:19685/api/card/pushQcGoodsId?goodsId=' + item.id, function (data) {
            //         // eval(data);
            //         console.log(data, 'Data属性')
            //     });
            // }

        },
        load() {
            // if(this.orderList.length>)
            this.$emit('next', 0)
        },
        remove(e) {
            console.log(this.orderList, '');
            this.orderList.splice(e, 1);
            this.$emit('delindex', e);
        }
    }
}
</script>

<style>
.order_list {
    margin-top: .125rem;
    /* height: 100%; */
    height: 800px;
}

.order_list li {
    padding: .125rem 0;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.order_l_t {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .16rem;
    color: #707071;
    margin-bottom: 0.05rem;
}

.order_l_t p span {
    color: #333;
}

.order_status_list {
    color: #ff0000;
    cursor: pointer;
    font-size: 0.2rem;
}

.order_c {
    display: flex;
    align-items: center;
}

.el-image {
    width: .6875rem;
    height: .6875rem;
}

.image-slot {
    width: .6875rem;
    height: .6875rem;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.25rem;
    color: #666;
}

.order_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.025rem 0;
    font-size: .1875rem;
    color: #707071;
    line-height: 0.3rem;
}

.green {
    color: #22d700 !important;
}

.red {
    color: #ff0000 !important;
}

.blue {
    color: #0099ff !important;
}

.yellow {
    color: #ffa100 !important;
}
</style>