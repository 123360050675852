<template>
	<div id="myChart" :style="{width: '100%', height: '312px'}" />
</template>

<script>
	export default {
		name: 'chart',
		data: function() {
			return {

			}
		},
		mounted() {
			this.$nextTick(() => {
				this.init()
			})
		},
		methods: {
			init() {
				const myChart = this.$echarts.init(document.getElementById('myChart'))
				myChart.setOption({
					xAxis: {
						type: 'category',
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
					},
					yAxis: {
						type: 'value'
					},
					grid: {
						left: '4%',
						right: '4%',
						bottom: '8%',
						top:'10%',
						containLabel: true
					},
					series: [{
						data: [150, 230, 224, 218, 135, 147, 260],
						type: 'line'
					}]
				})
				myChart.resize()
			}
		}
	}
</script>

<style>

</style>