<template>
    <div>
        <el-dialog title="" visible.sync="true" width="30%" center>
            <div class="centers">
                <i class="el-icon-check"></i>
                <p>{{statustext}}</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props:{
        statustext:{
            type:String,
            default:''
        },
        centerDialogVisible:{
            type:Boolean,
            default:false
        }
    },
    data: function () {
        return {
        }

    }
}
</script>

<style>
.centers{
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 0.2rem;
    color: #333;
}
.centers i{
    font-size: 0.8rem;
    color: #0099ff;
    margin-bottom: .375rem;
}
</style>