<template>
    <div class="receiving">
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <el-form :model="goodsForm">
                <el-form-item label="商品类别">
                    <el-select v-model="goodsForm.goodsType" placeholder="请选择商品类型">
                        <el-option v-for="(item,index) in goodsList" :label="item.title" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="getGoodsAdd">确定提交</el-button>
            </span>
        </el-dialog>
        <div class="order">
            <div class="order_top" v-if="authid == '2'">
                <p>已装袋：{{ orderList.length }}件</p>
            </div>
            <div class="order_content" style="padding: 0 0.125rem;overflow: hidden;">
                <order @clearOrder="sendClearOrder" :allPopNums="allPopNums" :goodsPopNums="goodsPopNums" :isVIP="isVIP" :indexFlag="indexFlag" :orderList="orderList"
                    style="overflow: hidden;flex: 1; text-overflow: ellipsis;" @getOrderDel="sendOrderAgain" @sendMsg="getMsg" @sendAdd="getAdd"
                    @orderStatus="handleChildEvent" @orderCategory="handleChildEvent1" allStatus="receiving"
                    :selectedorder="status" @delindex="removeindex" @orderIndex="getOrderIndex" :printNum="printNum">
                </order>
                <div class="bagging" v-if="authid == '2'">
                    <el-button style="width: 100%;" type="primary" @click="fengbox">装袋</el-button>
                </div>
            </div>
        </div>
        <div class="order_intro">
            <div class="order_i_top">
                <p>商品详情</p>
            </div>
            <div class="order_i_content">
                <div class="order_i_c_l">
                    <orderInfo @clear="sendClearOrder" :clearOrderInfo="clearOrderInfo" :scanRfid="scanRfid" @goPrint="goPrint" :orderIndex="orderIndex"
                        :orderLength="orderLength" :indexFlag="indexFlag" :rejectionFlag="rejectionFlag"
                        :authid="authid" :giveStatus="status" :orderid="ogid" :orderCategory="category"
                        @filelist="handlelist" @refresh="searchOrders" @remove="removeindex"></orderInfo>
                </div>
            </div>


        </div>
    </div>
</template>

<script>

import order from './order.vue'
import orderInfo from './orderInfo.vue'
import $ from 'jquery'
export default {
    components: {
        order,
        orderInfo
    },
    props: {
        authid: {
            type: String,
            default: ''
        },
        bigRfid: {
            type: String,
            default: ''
        },
        Recivelist: {
            type: Array
        },
        reciveimage: {
            type: Object
        },
        refresh: {
            type: Boolean
        },
        indexFlag: {
            type: String
        },
        newOrderId:{
            type: [Number,String],
            default: 0
        },
        newRfid:{
            type:String
        },
        isVIP:{
            type:Number
        },
        allPopNums:{
            type:[String,Number]
        },
        goodsPopNums:{
            type:[String,Number]
        }
    },
    watch: {
        newOrderId:{
            handler(newValue,oldValue){
                this.newIdOrder = newValue;
            }
        },
        newRfid:{
            handler(newValue,oldValue){
                this.scanRfid = newValue;
            }
        },
        Recivelist: {
            handler(orderData, newData) {
                // 监听到之后开始调用方法
                // console.log(orderData, newData)
                this.orderList = []
                // if (newData.length == 0) {
                const seen = new Set();
                orderData.forEach(item => {
                    const key = JSON.stringify(item);
                    if (!seen.has(key)) {
                        seen.add(key);
                        this.orderList.push(item);
                    }
                });
                console.log(this.orderList, '去重')
                // } else {
                //     this.orderList = newData
                // }

                this.ogid = this.orderList[0]
                this.status = 0
                this.laundry = false
            },
        },
        reciveimage: {
            handler(orderData, newData) {
                // 监听到之后开始调用方法
                if (orderData.goodsId == this.ogid.id) {
                    this.ogid.images_put = null
                    this.ogid.images_put = orderData.photo
                    // this.searchOrder()

                }
            },
        },
        refresh: {
            handler(orderData, newData) {
                // 监听到之后开始调用方法
                console.log(orderData, newData, '2222222222222refresh')
                if (orderData) {
                    this.searchOrder()
                    console.log(this.ogid)
                    let self = this
                    setTimeout(() => {
                        if (this.ogid.status == 12) {
                            // $.getJSON('http://127.0.0.1:19685/api/card/pushQcGoodsId?goodsId=' + self.ogid.id, function (data) {
                            //     // eval(data);
                            //     console.log(data)
                            // });

                        }
                    }, 2000);
                }
            }
        },
        rfid: {
            handler(newRfid, oldRfid) {
                this.$emit('rfid', newRfid);
            }
        }
    },
    data: function () {
        return {
            list: ['全部', '待揽件', '拒收件', '未质检', '已完成'],
            selected: 0,
            activeName: 'first',
            input1: '',
            orderList: [],
            littlebox: '',
            bigbox: '',
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            status: '',
            category: 'receiving',
            // authid: 0,
            laundry: false,
            ogid: '',
            fileList: [],
            rejectionFlag: false,
            orderLength: '',
            orderIndex: '',
            rfid: '',
            printNum: 0,
            dialogVisible: false,
            goodsForm: {
                goodsType: ''
            },
            goodsList:[],
            newIdOrder:'',
            scanRfid:'',
            clearOrderInfo:false
        }
    },
    mounted() {
        this.orderList = this.Recivelist
    },
    updated() {
        this.rejectionFlag = false;
        this.orderLength = this.orderList.length;
    },
    methods: {
        // 通知页面清空数据
        sendClearOrder(msg){
            this.$emit('clearAll',true)
        },
        // 确定添加商品回调
        getGoodsAdd(){
            let orderIdNum = this.newIdOrder;
            this.$http.post('order/addGoods',{uid:localStorage.getItem('id'),orderId:orderIdNum,goodsId:this.goodsForm.goodsType},
                (res) => {
                    if(res.code == 1){
                        this.$emit('gerOrdrList',this.scanRfid);
                        this.goodsForm.goodsType = ''
                    }
                })
            this.dialogVisible = false;
        },
        sendOrderAgain(){
            this.$emit('gerOrdrList',this.scanRfid);
            this.orderIndex = 1;
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        goPrint(msg) {
            this.printNum = this.printNum + 1;
            console.log(this.printNum, '打印次数');
        },
        getOrderIndex(e) {
            this.orderIndex = e + 1;
        },
        tab(e) {
            this.selected = e
        },
        handleNodeClick(data) {
            console.log(data);
        },

        handleChildEvent(e) {
            this.status = e
            this.ogid = this.orderList[e]
        },
        handleChildEvent1(e) {
            this.category = e
        },
        getMsg(msg) {
            this.rejectionFlag = msg;
        },
        getAdd(msg) {
            this.dialogVisible = msg;
            this.$http.post('goods/select',{switch:[0,1],levels:[1, 2, 3],pageSize:1000},(res => {
                if(res.code == 1){
                    this.goodsList = res.data;
                    this.orderIndex = 1
                }
            }))
        },
        getRfid(rfid) {
            this.rfid = rfid;
        },
        searchOrders() {
            if (this.orderList.length > 1) {
                this.searchOrder()
            } else {
                this.ogid = ''
                this.status = 0
                this.orderList = []
                $.getJSON('http://127.0.0.1:19685/api/card/beginQc?uid=' + localStorage.getItem('id'), function (data) {
                    // eval(data);
                    console.log(data)
                });
            }
        },
        removeindex(index) {
            this.orderList.splice(index, 1);
            if (this.orderList.length > 0) {
                this.ogid = this.orderList[0]
            } else {
                this.ogid = ''
            }
            this.$emit('delindex', index);
        },
        searchOrder() {
            var data = {
                rfid: this.orderList[0].rfid,
                id: localStorage.getItem('id'),
            }
            this.$http.post('/receiving/queryorder', data, (res) => {
                console.log(res, '查询接口')
                if (res.code == 1) {
                    this.orderList = res.data
                    for (var i = 0; i < this.orderList.length; i++) {
                        if (!this.orderList[i].images_put) {
                            this.orderList[i].images_put = []
                        }
                    }
                    this.ogid = this.orderList[0]

                    this.status = 0
                    this.laundry = false
                    console.log(this.ogid, 'ID000');

                    // if (JSON.parse(localStorage.getItem('userinfo')).authid == '1' && this.ogid.status === 12) {
                    //     $.getJSON('http://127.0.0.1:19685/api/card/pushQcGoodsId?goodsId=' + this.ogid.id, function (data) {
                    //         // eval(data);
                    //         console.log(data, 'Data属性')
                    //     });
                    // }
                    // }
                } else if (res.code == 2) {
                    this.bindLaundry()

                } else if (res.code == 6) {
                    this.orderList = []
                    this.ogid = ''
                    this.status = 0
                    this.fileList = []
                    this.input1 = ''
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                }
            })
        },
        searchOrder1() {
            if (this.bigbox == '') {
                this.$message({
                    type: 'error',
                    message: '请先绑定大袋子'
                })
                this.littlebox = ''
                return;
            }
            var data = {
                rfid: this.littlebox,
                id: localStorage.getItem('id'),
            }
            this.$http.post('/receiving/orderlist', data, (res) => {
                console.log(res)
                if (res.code == 1) {
                    this.orderList.unshift(res.data)
                    this.status = 0
                    this.ogid = this.orderList[0]
                    var datas = {
                        id: localStorage.getItem('id'),
                        ogid: res.data.id,
                        rfid: this.bigbox
                    }
                    this.$http.post('/receiving/bindbig', datas, (res) => {
                        console.log(res)
                        this.littlebox = ''
                        if (res.code == 1) {
                            this.$message({
                                type: 'success',
                                message: res.msg
                            })

                        } else {
                            this.$message({
                                type: 'error',
                                message: res.msg
                            })
                        }
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                }
            })
        },
        fengbox() {
            var ids = ''
            // for (var i = 0; i < this.orderList.length; i++) {
            //     ids = this.orderList.join(',')
            // }
            var gind = [];
            for (var i = 0; i < this.orderList.length; i++) {
                gind.push(this.orderList[i].id);
            }
            // 使用 join() 方法将数组中的 id 进行拼接
            var concatenatedIds = gind.join(',');
            console.log(concatenatedIds);


            var data = {
                rfid: localStorage.getItem('bigRfid'),
                id: localStorage.getItem('id'),
                ogid: concatenatedIds
            }
            console.log(data, '大袋子');

            this.$http.post('/receiving/envelope', data, (res) => {
                console.log(res)
                if (res.code == 1) {
                    this.$message({
                        message: '封袋成功',
                        type: 'success'
                    })
                    this.bigbox = ''
                    this.orderList = []
                    this.status = ''
                    this.ogid = ''
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })

        },
        watchBigbox() {
            var data = {
                rfid: this.bigbox,
                id: localStorage.getItem('id'),
            }
            this.$http.post('/receiving/orderlist', data, (res) => {
                console.log(res)
                if (res.code == 1) {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.orderList = []
                    this.status = []
                    this.ogid = ''
                } else {
                    this.bigbox = ''
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                }
            })
        },
        handlelist(e) {
            console.log(e, 'rere')
            this.fileList = e
        },
        bindLaundry() {
            if (this.fileList.length == 0) {
                this.$message({
                    message: '请先上传图片',
                    type: 'error'
                })
                this.input1 = ''
                return;
            }
            var data = {
                rfid: this.input1,
                ogid: this.orderList[this.status].id,
                id: localStorage.getItem('id'),
                image: this.fileList
            }
            this.$http.post('/receiving/queryorder', data, (res) => {
                console.log(res)
                if (res.code == 1) {
                    this.$message({
                        message: '绑定成功',
                        type: 'success'
                    })
                    this.input1 = this.orderList[0].rfid
                    this.fileList = []
                    this.searchOrder()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        }
    }
}
</script>

<style>
.receiving {
    display: flex;
    height: 100%;
}

.box_code {
    display: flex;
    align-items: center;
    padding: 0 0.125rem;
    margin: 0.125rem 0;
}

.box_code label {
    width: 1.2rem;
    font-size: 0.15rem;
}

.box_code .el-input__inner {
    height: 0.4rem;
    flex: 1;
}

.order {
    background: #fff;
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.order_top {
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: .175rem;
    padding: .125rem;
    border-bottom: 1px solid #eee;
}

.order_content {
    padding: 0 0.125rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.order_status {
    display: flex;
    align-items: center;
    font-size: .185rem;
    font-weight: 500;
    color: #333;
}

.order_status li {
    margin-right: .1875rem;
    cursor: pointer;
}

.order_status li.active {
    color: rgba(0, 153, 255, 1);
}

.order_intro {
    height: 100%;
    background: #fff;
    margin-left: .125rem;
    flex: 1;
}

.order_i_top {
    padding: .155rem .155rem 0 .225rem;
    border-bottom: 1px solid #eee;
}

.order_i_top p {
    height: .375rem;
    font-size: .1875rem;
    color: rgba(0, 153, 255, 1);
    border-bottom: 2px solid rgba(0, 153, 255, 1);
    display: inline-block;
}

.order_i_content {
    display: flex;
}

.order_i_c_l {
    padding: .1875rem;
    flex: 1;
}

.all_fenlei {
    height: .5rem;
    background: #d4e9ff;
    text-align: center;
    line-height: .5rem;
    font-size: 0.2rem;
    color: #48a3ff;
}

.bagging {
    margin: .25rem 0;
}
</style>