import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from "axios";
import 'element-ui/lib/theme-chalk/index.css';
import './utils/flexible.js'
import $ from 'jquery'
import http from "@/utils/request.js"
Vue.prototype.$http = http
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts //将echarts作为全局变量加入Vue
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
