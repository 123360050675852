<template>
    <div class="big">
        <div class="left">
            <div class="left-top">
                <el-input ref="inputRef" v-model="goodsId" placeholder="请扫描或输入水洗唛"></el-input>
                <el-button @click="searchInfo" type="primary">查询</el-button>
            </div>
            <div class="left-content">
                <div v-if="orderInfo.length != 0" @click="getGoodsInfo(index)"
                    :class="index == checkoutIndex ? 'left-content-item xxx' : 'left-content-item'"
                    v-for="(item, index) in orderInfo" :key="index">
                    <p>商品类型:{{ item.type_title }}</p>
                    <p>商品名称:{{ item.title }}</p>
                    <p>商品ID:{{ item.id }}</p>
                    <p>订单编号:{{ item.number }}</p>
                </div>
                <div v-if="orderInfo.length == 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
            </div>
        </div>
        <div v-if="showBigImg" style="position: absolute; z-index: 3; width: 100%; height: 100%; overflow: auto;">
            <img ref="image" @click="toBig(item)" :src="this.bigImgUrl" alt="">
        </div>
        <div :class="isTime ? 'right timeOut' : 'right'" v-if="orderInfo.length != 0">
            <div class="right-title">商品详情:</div>
            <ul class="info">
                <li>类型:{{ factoryInfo.goodsType }}</li>
                <li>颜色:{{ factoryInfo.goodsColor }}</li>
                <li>瑕疵:{{ factoryInfo.goodsFlaw }}</li>
                <li>附件:{{ factoryInfo.goodsFiles }}</li>
                <li>洗涤风险:{{ factoryInfo.goodsRisk }}</li>
                <li>备注:{{ factoryInfo.goodsNotes }}</li>
            </ul>
            <div class="right-title">流转记录:</div>
            <div style="height: 300px; overflow: auto;">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="time" label="日期" width="180">
                    </el-table-column>
                    <el-table-column prop="work" label="工段" width="180">
                    </el-table-column>
                    <el-table-column prop="uid" label="操作员">
                    </el-table-column>
                </el-table>
            </div>
            <div class="right-title">拆包照片:</div>
            <ul class="image-item">
                <li class="" v-for="item in goodsImg" style="display: flex;">
                    <img @click="toBig(item)" :src="item" alt="">
                </li>
            </ul>
        </div>
        <div v-if="orderInfo.length == 0" style="margin: 0 auto;">
            <el-empty description="暂无数据"></el-empty>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {

    },
    props: {
        indexFlag: {
            type: String
        },
    },
    data() {
        return {
            goodsId: '',
            orderInfo: [],
            checkoutIndex: 0,
            goodsInfo: {},
            factoryInfo: {},
            goodsImg: [],
            tableData: [],
            allIndex: '',
            bigImgUrl:'',
            showBigImg:false,
            isTime:false
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('keyup', (e) => {
                if (e.repeat) {
                    return;
                }
                if (e.keyCode == 13) {
                    this.searchInfo();
                }else if (e.keyCode == 27) {
                    this.showBigImg = false;
                }
            })
        });
    },
    watch: {
        indexFlag(newValue, oldValue) {
            console.log(newValue);
            if (newValue == 6) {
                this.$refs.inputRef.focus();
                this.allIndex = newValue;
            }
        },
        immediate: true,
        deep: true
    },
    computed: {

    },
    methods: {
        // 查询订单时间
        searchOrderTome(id){
            console.log(id);
            this.$http.post('order/getOrderTracking',{order_id:id,status:'22'},(res => {
                if(res.code == 1){
                    let orderDate = new Date(res.data.data[0].changeTime);
                    let timeoutDate = new Date(orderDate.getTime() + 40 * 60 * 60 * 1000);
                    let now = new Date();
                    const nowTime = now.toLocaleString();
                    let changeNow = new Date(nowTime)
                    this.isTime = changeNow > timeoutDate
                    console.log(this.isTime);
                }
            }))
        },
        // 图片放大回调
        toBig(url){
            this.bigImgUrl = url;
            this.showBigImg = true;
        },
        // 查询商品回调
        searchInfo() {
            let data = {
                id: JSON.parse(localStorage.getItem('userinfo')).id,
                ogid: this.goodsId,
            }
            if (this.allIndex == 6) {
                this.$http.post(`/receiving/waterWashedLabelOrderInfo`, data, (res => {
                    if (res.code == 1) {
                        if (res.data.length == 0) {
                            this.$message({
                                message: '暂无信息',
                                type: 'error'
                            })
                        } else {
                            this.orderInfo = res.data;
                            this.searchOrderTome(res.data[0].order_id,'订单ID')
                            this.goodsInfo = this.orderInfo[this.checkoutIndex];
                            this.factoryInfo = JSON.parse(this.orderInfo[this.checkoutIndex].factory_use_info);
                            this.goodsImg = this.orderInfo[this.checkoutIndex].images_put;
                            this.tableData = this.factoryInfo.goodsRecord;
                            this.allIndex = '';
                        }
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                }))
            }
            this.goodsId = '';
        },
        // 点击商品列表
        getGoodsInfo(index) {
            this.checkoutIndex = index;
            this.goodsInfo = this.orderInfo[this.checkoutIndex];
            this.factoryInfo = JSON.parse(this.orderInfo[this.checkoutIndex].factory_use_info);
            this.goodsImg = this.orderInfo[this.checkoutIndex].images_put;
            this.tableData = this.factoryInfo.goodsRecord
        }

    },
}
</script>

<style lang="scss" scoped>
.big {
    width: 100%;
    // height: 100%;
    height: 800px;

    background-color: white;
    display: flex;

    .left {
        width: 22%;
        height: 100%;
        border-right: 1px solid black;

        .left-top {
            padding: 5px;
            display: flex;
            justify-content: space-between;
        }

        .left-content {
            .left-content-item {
                width: 100%;
                height: 140px;
                padding: 20px;
                font-size: 14px;

                p {
                    margin-bottom: 10px;
                }
            }

            .xxx {
                background-color: #eee;
            }
        }
    }

    .right {
        overflow: auto;
        margin-left: 10px;

        // height: 700px;
        .right-title {
            font-size: 18px;
            color: #0099ff;
        }

        .info {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            margin-bottom: 10px;

            li {
                font-size: 18px;
                border: 1px solid #eee;
                width: 500px;
                height: 30px;
                color: gray;
                line-height: 30px;
            }
        }

        .image-item {
            display: flex;
            flex-wrap: wrap;

            img {
                width: 400px;
                height: 300px;
                margin: 5px;
            }
        }
    }
    .timeOut{
        background-image: url(../../assets/images/timeOut.png);
        background-repeat: no-repeat;
        background-position: 1050px 20px;
        background-size: 140px 140px;
    }
}
</style>