<template>
    <div class="receiving">
        <div class="order">
            <!-- <div class="order_top">
                <p>已装袋：5件</p>
            </div> -->
            <!-- <div class="box_code" style="border-bottom: 1px solid #ddd;padding-bottom: .25rem;">
                <label>小袋子编码：</label>
                <el-input placeholder="输入小袋子编码" v-model="input1" @keyup.enter.native="getOrderList" clearable></el-input>
            </div> -->
            <div class="order_content" style="padding: 0 0.125rem;">
                <!-- <ul class="order_status">
                    <li v-for="item, index in list" :key="index" :class="selected == index ? 'active' : ''"
                        @click="tab(index)">
                        {{ item }}</li>
                </ul> -->
                <order :zhijian="zhijian" :orderList="orderList" @orderStatus="handleChildEvent"
                    @orderCategory="handleChildEvent1" allStatus="inspection"></order>
                <!-- <div class="bagging">
                    <el-button style="width: 100%;" type="primary">装袋</el-button>
                </div> -->
            </div>
        </div>
        <div class="order_intro">
            <div class="order_i_top">
                <p>订单详情</p>
            </div>
            <div class="order_i_content">
                <div class="order_i_c_l">
                    <!-- <el-input placeholder="输入商品条形码或商品名称" v-model="input1">
                        <template slot="prepend">
                            <i class="el-icon-search"></i>
                        </template>
</el-input> -->
                    <orderInfo :zhijian="zhijian" :inputmax="input1" :authid="authid" :orderid="ogid" :giveStatus="status"
                        :orderCategory="category" @refresh="refreshs" @backAdd="backAdd" @filelistqua="handlelist" @twoOrder="handleTwo">
                    </orderInfo>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import order from './order.vue'
import orderInfo from './orderInfo.vue'
export default {
    components: {
        order,
        orderInfo
    },
    props: {
        authid: {
            type: String,
            default: ''
        },
        Inspectionlist: {
            type: Array
        },
        zhijianlist: {
            type: Array
        },
        refresh:{
            type:Boolean
        }
    },
    watch: {
        Inspectionlist: {
            handler(orderData, newData) {
                // 监听到之后开始调用方法
                console.log(orderData, newData)
                this.orderList = []
                // if (newData.length == 0) {
                this.orderList = orderData
                // } else {
                //     this.orderList = newData
                // }

                this.ogid = this.orderList[0]
                // this.status = 0
                this.zhijian = false
            },
        },
        zhijianlist: {
            handler(oldData, newData) {
                console.log(oldData, newData)
                this.ogid.images_qua = null
                this.ogid.images_qua = oldData
                // this.zhijian = true
                this.orderList1 = []

            }
        },
        refresh: {
            handler(orderData, newData) {
                // 监听到之后开始调用方法
                console.log(orderData, newData, '2222222222222refresh')
                if (orderData) {
                    this.zhijian = true
                    this.orderList1 = []
                }
            }
        }
        },
        data: function () {
            return {
                list: ['全部', '待揽件', '拒收件', '未质检', '已完成'],
                selected: 0,
                activeName: 'first',
                input1: '',
                status: 0,
                orderList: [],
                category: 'inspection',
                ogid: '',
                fileList: [],
                orderList1: [],
                zhijian: false
            }
        },
        methods: {
            tab(e) {
                this.selected = e
            },
            handleNodeClick(data) {
                console.log(data);
            },
            handleChildEvent(e) {
                console.log(e)
                this.status = e
            },
            handleChildEvent1(e) {
                this.category = e
            },
            refreshs() {
                this.ogid = ''
                this.input1 = ''
                this.orderList = []
            },
            handleTwo(e) {
                console.log(e)
                // this.orderList1 = e
                // this.input1 = e.rfid_put
                // this.fileList = []
                // this.getOrderList()
                this.ogid = ''
                this.input1 = ''
                this.orderList = []
            },
            getOrderList() {
                console.log(this.input1)
                if (this.input1 == '') {
                    this.refresh()
                    return;
                }
                if (this.orderList1.length > 0) {
                    if (this.fileList.length == 0) {
                        this.$message({
                            message: '请先上传图片，再扫码',
                            type: 'error'
                        })
                        this.input1 = ''
                        return;
                    }
                }
                // console.log(typeof this.input1,'编码id');
                localStorage.setItem('dataCoding',this.input1)
                var data = {
                    rfid: this.input1,
                    id: localStorage.getItem('id'),
                    ogid: this.orderList1.length > 0 ? this.orderList[0].id : '',
                    image: this.fileList.length > 0 ? this.fileList : ''
                }
                this.$http.post('/quality/querylist', data, (res) => {
                    console.log(res)
                    if (res.code == 1) {
                        if (res.data) {
                            this.fileList = []
                            this.orderList = []
                            this.orderList1 = []
                            this.orderList.push(res.data)
                            if (this.orderList[0].images_qua == '' || this.orderList[0].images_qua == null) {
                                this.orderList[0].images_qua = []
                            }
                            this.orderList1.push(res.data)
                            if (this.orderList1[0].images_qua == '' || this.orderList1[0].images_qua == null) {
                                this.orderList1[0].images_qua = []
                            }
                            this.ogid = this.orderList[0]
                            this.zhijian = false

                        } else {
                            this.$message({
                                message: '绑定成功',
                                type: 'success'
                            })
                            this.zhijian = true
                            this.orderList1 = []
                        }
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            },
            // bindsong(){
            //     var data = {
            //         rfid: this.input1,
            //         ogid:this.orderid.id,
            //         id: localStorage.getItem('id')
            //     }
            //     this.$http.post('/quality/bindpua', data, (res) => {
            //         console.log(res)
            //         if (res.code == 1) {
            //             this.$message({
            //                 message: res.msg,
            //                 type: 'success'
            //             })
            //         } else {
            //             this.$message({
            //                 message: res.msg,
            //                 type: 'error'
            //             })
            //         }
            //     })
            // },
            bindLaundry() {
                if (this.fileList.length == 0) {
                    this.$message({
                        message: '请先上传图片',
                        type: 'error'
                    })
                    // this.input1 = ''
                    return;
                }
                var data = {
                    rfid: this.input1,
                    ogid: this.orderList[this.status].id,
                    id: localStorage.getItem('id'),
                    image: this.fileList
                }
                this.$http.post('/receiving/bindpua', data, (res) => {
                    console.log(res)
                    if (res.code == 1) {
                        this.$message({
                            message: '绑定成功',
                            type: 'success'
                        })
                        this.input1 = this.orderList[0].rfid
                        // this.searchOrder()
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            },
            handlelist(e) {
                console.log(e, 'rere')
                this.fileList = e
            }
        }
    }
</script>

<style>
.receiving {
    display: flex;
    height: 100%;
}

.box_code {
    display: flex;
    align-items: center;
    padding: 0 0.125rem;
    margin: 0.125rem 0;
}

.box_code label {
    width: 1.2rem;
}

.box_code .el-input__inner {
    height: 0.4rem;
    flex: 1;
}

.order {
    background: #fff;
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.order_top {
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: .175rem;
    padding: .125rem;
    border-bottom: 1px solid #eee;
}

.order_content {
    padding: 0 0.125rem;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.order_status {
    display: flex;
    align-items: center;
    font-size: .185rem;
    font-weight: 500;
    color: #333;
}

.order_status li {
    margin-right: .1875rem;
    cursor: pointer;
}

.order_status li.active {
    color: rgba(0, 153, 255, 1);
}

.order_intro {
    height: 100%;
    background: #fff;
    margin-left: .125rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.order_i_top {
    padding: .155rem .155rem 0 .225rem;
    border-bottom: 1px solid #eee;
}

.order_i_top p {
    height: .375rem;
    font-size: .1875rem;
    color: rgba(0, 153, 255, 1);
    border-bottom: 2px solid rgba(0, 153, 255, 1);
    display: inline-block;
}

.order_i_content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;

    /* height: 100%; */
}

.order_i_c_l {
    padding: .1875rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.all_fenlei {
    height: .5rem;
    background: #d4e9ff;
    text-align: center;
    line-height: .5rem;
    font-size: 0.2rem;
    color: #48a3ff;
}

.bagging {
    margin-bottom: .375rem;
}
</style>