<template>
    <div class="receiving">
        <div class="order">
            <div class="order_top">
                <p>待处理：{{resNum}}件</p>
                <!-- <p>拒收：5件</p> -->
            </div>
            <div class="order_content">
                <ul class="order_status">
                    <li v-for="item, index in list" :key="index" :class="selected == index ? 'active' : ''"
                        @click="tab(index)">
                        {{ item.name }}</li>
                </ul>
                <order :orderList="orderList" style="overflow: hidden;flex: 1; text-overflow: ellipsis;" @orderStatus="handleChildEvent" @orderCategory="handleChildEvent1" allStatus="allOrder" @next="next()"></order>
                <!-- <order @orderStatus="handleChildEvent" @orderCategory="handleChildEvent1" allStatus="allOrder"></order>
                <order @orderStatus="handleChildEvent" @orderCategory="handleChildEvent1" allStatus="allOrder"></order> -->

            </div>
        </div>
        <div class="order_intro">
            <div class="order_i_top">
                <p>订单详情</p>
            </div>
            <div class="order_i_content">
                <div class="order_i_c_l">
                    <!-- <el-input placeholder="输入商品条形码或商品名称" v-model="input1">
                        <template slot="prepend">
                            <i class="el-icon-search"></i>
                        </template>
                    </el-input> -->
                    <orderInfo :authid="authid" :orderid="ogid" :giveStatus="status" :orderCategory="category"></orderInfo>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import order from './order.vue'
import orderInfo from './orderInfo.vue'
export default {
    components: {
        order,
        orderInfo
    },
    props:{
        authid:{
            type:String,
            default:''
        },
        selecttab: {
			type: [Number, String],
			default: ''
		}
    },
    watch: {
		selecttab: {
			handler(newValue, oldValue) {
				console.log(newValue)
				if (newValue == '1') {
                    this.getOrderList()

				}
			}
		}
	},
    data: function () {
        return {
            list: [{
                name:'拒收件',
                status:31
            }, {
                name:'未质检',
                status:20
            }, {
                name:'清洗完成',
                status:21
            }, {
                name:'已收件',
                status:11
            }],
            selected: 0,
            activeName: 'first',
            input1: '',
            orderList:[],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            status:0,
            category:'allOrder',
            page:1,
            ogid:'',
            resNum: 0,
            orderList1:[]
        }
    },
    mounted(){
        // this.getOrderList()
    },
    methods: {
        tab(e) {
            this.selected = e
            this.page = 1
            this.orderList = []
            this.ogid = ''
            this.status = 0
            this.getOrderList()
        },
        handleNodeClick(data) {
            console.log(data);
        },
        next(){
            this.page += 1
            this.getOrderList()
        },
        handleChildEvent(e){
            console.log(e)
            this.status = e
            this.getOrderdetails()
        },
        handleChildEvent1(e){
            this.category = e
        },
        getOrderdetails(){
            var data = {
                ogid:this.orderList[this.status].id,
                id:localStorage.getItem('id')
            }
            this.$http.post('/index/getdetails', data, (res) => {
                console.log(res)
                if (res.code == 1) {
                    this.ogid = res.data
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        getOrderList(){
            var data = {
                id:localStorage.getItem('id'),
                status:this.list[this.selected].status,
                page:this.page
            }
            this.$http.post('/index/getlist', data, (res) => {
				console.log(res)
				if (res.code == 1) {
                    this.resNum = res.data.total
					this.orderList = [...this.orderList,...res.data.list]
                    if(res.data.list.length>0){
                      this.getOrderdetails()
                    }
					// this.day = res.data.data.day
					this.total = res.data.total
				}
			})
        }
    }
}
</script>

<style>
.receiving {
    display: flex;
    height: 100%;
}

.order {
    background: #fff;
    width: 20%;
    height: 100%;

}

.order_top {
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: .175rem;
    padding: .125rem;
    border-bottom: 1px solid #eee;
}

.order_content {
    padding: 0.25rem .125rem;
}

.order_status {
    display: flex;
    align-items: center;
    font-size: .185rem;
    font-weight: 500;
    color: #333;
}

.order_status li {
    margin-right: .1875rem;
    cursor: pointer;
}

.order_status li.active {
    color: rgba(0, 153, 255, 1);
}

.order_intro {
    height: 100%;
    background: #fff;
    margin-left: .125rem;
    flex: 1;
}

.order_i_top {
    padding: .155rem .155rem 0 .225rem;
    border-bottom: 1px solid #eee;
}

.order_i_top p {
    height: .375rem;
    font-size: .1875rem;
    color: rgba(0, 153, 255, 1);
    border-bottom: 2px solid rgba(0, 153, 255, 1);
    display: inline-block;
}

.order_i_content {
    display: flex;
}

.order_i_c_l {
    padding: .1875rem;
    flex: 1;
}
.all_fenlei{
    height: .5rem;
    background: #d4e9ff;
    text-align: center;
    line-height: .5rem;
    font-size: 0.2rem;
    color: #48a3ff;
}
</style>