<template>
    <div class="big">
        <div class="left">
            <p>请选择工位:</p>
            <ul class="convert-list">
                <li :class="convertListIndex == index
                ? 'convert-check convert-item'
                : 'convert-item'
                " v-for="(item, index) in convertList" :key="index" @click="checkWorkstation(item, index)">
                    {{ item }}
                </li>
            </ul>
        </div>
        <div class="right">
            <p>
                当前工位:<span style="color: red; margin-left: 20px">{{
                convertNow
            }}</span>
            </p>
            <div class="right-top">
                <el-input ref="goodsInput" v-model="goodsId" placeholder="请扫描或输入水洗唛流转商品"></el-input>
                <el-button @click="searchGoods" style="margin-left: 50px" type="primary">流转</el-button>
                <!-- <el-button @click="sendGoods" style="margin-left: 50px" type="success">流转</el-button> -->
            </div>
            <!-- <div :class="isTime ? 'right-content timeOut' : 'right-content'"> -->
            <div class="right-content">
                <p>流转记录:</p>
                <div style="height: 500px; overflow: auto;">
                    <el-table class="transparent-table" :data="tableData">
                        <el-table-column prop="time" label="流转日期" width="200">
                        </el-table-column>
                        <el-table-column prop="gId" label="水洗唛" width="200">
                        </el-table-column>
                        <el-table-column prop="work" label="工位" width="200">
                        </el-table-column>
                        <el-table-column prop="uid" label="操作员" width="200"> </el-table-column>
                    </el-table>
                </div>
                <div class="timeOutimg" v-show="isTime">
                    <img src="../../assets/images/timeOut.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    components: {},
    data() {
        return {
            convertList: [
                "分类检查",
                "衣物质检",
                "扫码出厂",
                "缝补修复",
                "水洗预处理",
                "后去渍台",
                "喷涂",
                "鞋分类检查",
                "鞋手洗整双",
                "鞋手洗机洗后",
                "鞋烘干",
                "鞋后质检",
                "鞋修补",
                "粘鞋底",
                "客服洗前",
                "客服洗后",
                "回烘干",
                "粘毛",
                "水洗回洗",
                "干洗预处理",
                "干洗回洗",
                "反洗客服",
                "客诉处理",
                "拆包问题单"
            ],
            convertListIndex: "x",
            convertNow: "",
            tableData: [],
            goodsId:'',
            goodsInfo:{},
            recordTime:'',
            isTime:false
        };
    },
    computed: {},
    mounted(){
        this.getEntryTime();
        this.$nextTick(() => {
			window.addEventListener('keyup', (e) => {
				if (e.repeat) {
					return;
				}
				if (e.keyCode == 13) {
					if (this.indexFlag == '3') {
						this.searchGoods();
					}
				}
			})
		})
    },
    watch: {
        indexFlag(newValue, oldValue) {
            if(newValue == '3'){
                this.$refs.goodsInput.focus();
            }
        }
    },
    props: {
        indexFlag: {
            type: String
        },
    },
    methods: {
        // 查询订单时间
        searchOrderTome(id){
            console.log(id);
            this.$http.post('order/getOrderTracking',{order_id:id,status:'22'},(res => {
                if(res.code == 1){
                    let orderDate = new Date(res.data.data[0].changeTime);
                    let timeoutDate = new Date(orderDate.getTime() + 40 * 60 * 60 * 1000);
                    let now = new Date();
                    const nowTime = now.toLocaleString();
                    let changeNow = new Date(nowTime)
                    this.isTime = changeNow > timeoutDate
                    console.log(this.isTime);
                }
            }))
        },
        getEntryTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const day = now.getDate();
            const time = now.toLocaleTimeString();
            this.recordTime = `${year}-${month}-${day} ${time}`;
        },
        // 查询商品详情
        searchGoods(){
            this.getEntryTime();
            this.$http.post(`order/getOrderGoodsDetail?ogId=${this.goodsId}`,null,(res => {
                if(res.code == 1){
                    let info = JSON.parse(res.data.factory_use_info)
                    this.goodsInfo = info;
                    let gId = res.data.id;
                    console.log(gId);
                    
                    const newArray = info.goodsRecord.map(item => ({...item,gId}));
                    console.log(newArray);
                    
                    this.tableData = newArray;
                    this.searchOrderTome(res.data.order_id);
                    if(this.convertNow == ''){
                        this.$message({
                            message: '请先选择工位',
                            type: 'error'
                        })
                    }else{
                        this.sendGoods();
                    }
                }else{
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            }))
        },
        // 流转回调
        sendGoods(){
            let obj = {
                time:this.recordTime,
                uid:JSON.parse(localStorage.getItem('userinfo')).id,
                work:this.convertNow
            }
            if(this.goodsInfo.goodsRecord == undefined){
                let record = [];
                record.push(obj);
                let data = {
                    goodsType: this.goodsInfo.goodsType,
                    goodsColor: this.goodsInfo.goodsColor,
                    goodsFiles: this.goodsInfo.goodsFiles,
                    goodsFlaw: this.goodsInfo.goodsFlaw,
                    goodsRisk: this.goodsInfo.goodsRisk,
                    goodsNotes: this.goodsInfo.goodsNotes,
                    goodsRecord: record
                }
                let params = JSON.stringify(data);
                this.$http.post('order/updateGoodsFactoryData',{ogId:this.goodsId,data:params},(res => {
                    if(res.code == 1){
                        this.$message({
                            message: '流转成功',
                            type: 'success'
                        })
                        this.$http.post(`order/getOrderGoodsDetail?ogId=${this.goodsId}`,null,(res => {
                            let info = JSON.parse(res.data.factory_use_info)
                            this.goodsInfo = info;
                            this.tableData = info.goodsRecord;
                            let gId = res.data.id;
                            const newArray = info.goodsRecord.map(item => ({...item,gId}));
                            console.log(newArray);
                            this.tableData = newArray;
                            this.searchOrderTome(res.data.order_id);

                        }))
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                }))
            }else{
                this.goodsInfo.goodsRecord.push(obj);
                let data = {
                    goodsType: this.goodsInfo.goodsType,
                    goodsColor: this.goodsInfo.goodsColor,
                    goodsFiles: this.goodsInfo.goodsFiles,
                    goodsFlaw: this.goodsInfo.goodsFlaw,
                    goodsRisk: this.goodsInfo.goodsRisk,
                    goodsNotes: this.goodsInfo.goodsNotes,
                    goodsRecord: this.goodsInfo.goodsRecord
                }
                let params = JSON.stringify(data);
                this.$http.post('order/updateGoodsFactoryData',{ogId:this.goodsId,data:params},(res => {
                    if(res.code == 1){
                        this.$message({
                            message: '流转成功',
                            type: 'success'
                        })
                        this.$http.post(`order/getOrderGoodsDetail?ogId=${this.goodsId}`,null,(res => {
                            let info = JSON.parse(res.data.factory_use_info)
                            this.goodsInfo = info;
                            this.tableData = info.goodsRecord;
                            let gId = res.data.id;
                            const newArray = info.goodsRecord.map(item => ({...item,gId}));
                            console.log(newArray);
                            this.tableData = newArray;
                            this.goodsId = '';
                            this.searchOrderTome(res.data.order_id);
                        }))
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                }))
            }
        },
        // 点击工段回调
        checkWorkstation(item, index) {
            this.convertListIndex = index;
            this.convertNow = item;
            this.$refs.goodsInput.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
.big {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;

    .left {
        width: 25%;
        height: 100%;
        border-right: 1px solid rgb(216, 213, 213);

        .convert-list {
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;

            .convert-item {
                border-radius: 5px;
                margin: 10px;
                height: 50px;
                padding: 10px;
                background-color: aliceblue;
            }

            .convert-check {
                background-color: aqua;
            }
        }
    }

    .right {
        margin: 20px;

        .right-top {
            margin-top: 20px;
        }

        .right-content {
            margin-top: 20px;
            display: flex;
            .timeOutimg{
                position: fixed;
                left: 900px;
                top: 300px;
                img{
                    height: 300px;
                    width: 300px;
                }
            }
        }
    }
}
</style>
