<template>
    <div class="containers">
        <div class="login">
            <div class="bg"></div>
            <div class="login_form">
                <h2>厂商端</h2>
                <el-form ref="form" :model="form" class="form" :rules="rules">
                    <el-form-item prop="username">
                        <el-input v-model="form.username" prefix-icon="el-icon-user-solid"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="form.password" prefix-icon="el-icon-s-goods"
                            suffix-icon="el-icon-view"></el-input>
                    </el-form-item>
                    <el-button type="primary" style="width: 100%;background: #4073fa;" @click="login">登录</el-button>
                </el-form>
            </div>
        </div>
        <div class="footer">
            Copyright © 2019-2020 公司名称 备案号：京ICP证000000号
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'home',

    data: function () {
        return {
            form: {
                username: '',
                password: ''
            },
            rules: {
                username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
            }
        }

    },
    mounted() {
        document.querySelector('.containers').style.height = document.documentElement.clientHeight + 'px';
    },
    methods: {
        login() {
            localStorage.clear()
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$http.post('/index/login', this.form, (res) => {
                        console.log(res)
                        if(res.code == 1){
                            localStorage.setItem('userinfo',JSON.stringify(res.data.userinfo))
                            localStorage.setItem('id',res.data.userinfo.id)
                            localStorage.setItem('httpimg',res.data.httpimg)
                            localStorage.setItem('httpip',res.data.httpip)
                            this.$router.push('/')

                        }else{
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });

            
        }
    }
}
</script>

<style>
.containers {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../assets/images/login_bg.png') no-repeat;
    background-size: 100% 100%;
}

.login {
    width: 10rem;
    height: 5rem;
    display: flex;
    box-shadow: .0375rem 0 .125rem rgba(0, 0, 0, .08);
    border-radius: .25rem;
    overflow: hidden;
    background: #fff;
}

.bg {
    background: #4073fa;
    width: 50%;
    height: 100%;
}

.login_form {
    padding: 0.625rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login_form h2 {
    text-align: center;
    margin-bottom: 0.6rem;
}

.el-input {
    width: 3.5rem !important;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #999;
    font-size: 0.15rem;
    height: 0.7rem;
    line-height: 0.7rem;
}
</style>