<template>
  <div class="big">
    <div class="left">
      <p class="left-title">待出厂</p>
      <div class="left-input">
        <el-input v-model="orderNumber" placeholder="请扫描或输入订单号"></el-input>
        <el-button type="primary">扫码出厂</el-button>
      </div>
      <div class="orderList">
        <ul>
          <li>
            <span>订单编号:JKJX24091216322199570</span>
            <el-button style="width: 74px;" type="primary">出厂</el-button>
          </li>
          <li>
            <span>订单编号:JKJX24091216322199570</span>
            <el-button style="width: 74px;" type="primary">出厂</el-button>
          </li>
          <li>
            <span>订单编号:JKJX24091216322199570</span>
            <el-button style="width: 74px;" type="primary">出厂</el-button>
          </li>
          <li>
            <span>订单编号:JKJX24091216322199570</span>
            <el-button style="width: 74px;" type="primary">出厂</el-button>
          </li>
          <li>
            <span>订单编号:JKJX24091216322199570</span>
            <el-button style="width: 74px;" type="primary">出厂</el-button>
          </li>
          <li>
            <span>订单编号:JKJX24091216322199570</span>
            <el-button style="width: 74px;" type="primary">出厂</el-button>
          </li>
          <li>
            <span>订单编号:JKJX24091216322199570</span>
            <el-button style="width: 74px;" type="primary">出厂</el-button>
          </li>
          <li>
            <span>订单编号:JKJX24091216322199570</span>
            <el-button style="width: 74px;" type="primary">出厂</el-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="right">
      <p>已出厂待配送</p>
      <div class="orderTable">
        <el-table border :data="tableData">
          <el-table-column prop="date" label="出厂日期" width="240">
          </el-table-column>
          <el-table-column prop="number" label="订单编号" width="280">
          </el-table-column>
          <el-table-column prop="uid" label="操作员ID">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  data() {
    return {
      orderNumber: '',
      tableData: [{
        date: '2024/9/12 16:33:40',
        number: 'JKJX24091216322199570',
        uid: '8'
      }, {
        date: '2024/9/12 16:33:40',
        number: 'JKJX24091216322199570',
        uid: '8'
      },{
        date: '2024/9/12 16:33:40',
        number: 'JKJX24091216322199570',
        uid: '8'
      },{
        date: '2024/9/12 16:33:40',
        number: 'JKJX24091216322199570',
        uid: '8'
      }]
    }
  },
  computed: {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.big {
  display: flex;

  .left {
    width: 462px;
    height: 830px;
    background-color: #FFFFFF;

    .left-title {
      margin-top: 23px;
      margin-left: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #2D4164;
      line-height: 28px;
      text-align: left;
      font-style: normal;
    }

    .left-input {
      margin-left: 16px;
      margin-top: 24px;
    }

    .orderList {
      margin-left: 16px;

      ul {
        height: 680px;
        margin-top: 40px;
        overflow: auto;

        li {
          width: 420px;
          height: 72px;
          border: 1px solid #EEEEEE;
          line-height: 72px;
          margin-bottom: 20px;

          span {
            margin-left: 12px;
            margin-right: 24px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 18px;
            color: #666666;
            line-height: 48px;
            text-align: center;
            font-style: normal;
          }
        }
      }
    }
  }

  .right {
    padding-left: 16px;
    padding-top: 23px;
    margin-left: 16px;
    width: 100%;
    height: 830px;
    background-color: #FFFFFF;

    p {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #2D4164;
      line-height: 28px;
      text-align: left;
      font-style: normal;
    }
    .orderTable{
      margin-top: 24px;
    }
  }
}
</style>