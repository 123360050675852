<template>
    <div>
        <div class="big" v-if="isShow">
            <div class="left">
                <div :class="isTime ? 'content timeOut' : 'content'">
                    <p>商品信息:</p>
                    <div class="content-line"></div>
                    <ul ref="goodsInfo">
                        <li>商品ID:<span>{{ goodsInfo.id }}</span></li>
                        <li>商品类型:<span>{{ goodsInfo.type_title }}</span></li>
                        <li>商品名称:<span>{{ goodsInfo.title }}</span></li>
                        <li>下单时间:<span>{{ goodsInfo.createtime }}</span></li>
                        <!-- <li>地区:<span>{{ goodsInfo.address }}</span></li> -->
                        <!-- <li>是否加急:<span>{{goodsInfo.urgentDetail}}</span></li> -->
                        <li>条码编号:
                            <span>{{ codeNum }}</span>
                        </li>
                    </ul>
                </div>
                <div class="bottom">
                    <el-button class="btn" type="primary" @click="toPrintStick">打印贴条</el-button>
                </div>
            </div>
            <div class="right">
                <div class="right-top">
                    <p>贴条预览</p>
                </div>
                <canvas style="display: none;" ref="barcodeCanvas"></canvas>
                <!-- <span ref="barcodeImg"></span> -->
                <div style="display: none;" class="right-content" ref="rightContent">
                    <div class="barcode" ref="barcodeImg"></div>
                </div>
                <div ref="printStick">
                    <img class="stickImg" style="width: 320px; height: 160px;" :src="imgUrl" alt="">
                    <p style="font-size: 10px; text-align: center;">{{ goodsInfo.group_no }}</p>
                </div>
            </div>
        </div>
        <div class="empty" v-else>
            <el-empty class="empty-content" description="暂无商品信息,请扫描水洗唛条码"></el-empty>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
export default {
    name: "",
    components: {},
    data() {
        return {
            capturedImage: null,
            goodsId: '',
            goodsInfo: {},
            isShow: false,
            imgUrl: '',
            codeNum:'',
            isTime:false
        };
    },
    props: {
        indexFlag: {
            type: String,
            default: ''
        }
    },
    mounted() {
        if(window == undefined){
            return;
        }else{
            window.addEventListener('keyup', (e) => {
                if (e.repeat) {
                    return;
                }
                if(this.indexFlag == '7'){
                    this.goodsId += String.fromCharCode(e.keyCode);
                }
                if (e.keyCode == 13) {
                    if (this.indexFlag == '7') {
                        this.getPrintStick();
                    }
                }
                window.addEventListener('focus', this.handleWindowFocus);
            });
        }
    },
    updated() {
        this.generateBarcode();
    },
    computed: {},
    methods: {
        // 查询订单时间
        searchOrderTome(id){
            console.log(id);
            this.$http.post('order/getOrderTracking',{order_id:id,status:'22'},(res => {
                if(res.code == 1){
                    let orderDate = new Date(res.data.data[0].changeTime);
                    let timeoutDate = new Date(orderDate.getTime() + 40 * 60 * 60 * 1000);
                    let now = new Date();
                    const nowTime = now.toLocaleString();
                    let changeNow = new Date(nowTime)
                    this.isTime = changeNow > timeoutDate
                    console.log(this.isTime);
                }
            }))
        },
        handleWindowFocus() {
            this.goodsId = '';
            this.isShow = false;
            this.goodsInfo = {};
            this.capturedImage = null;
        },
        // 截取html结构回调
        captrueHtml() {
            const barCodeImg = this.$refs.printStick;
            html2canvas(barCodeImg).then(canvas => {
                this.capturedImage = canvas.toDataURL('image/png');
            })
        },
        // 扫码回调
        async getPrintStick() {
            let obj = {
                id: this.goodsId,
                uid:JSON.parse(localStorage.getItem('userinfo')).id
            }
            if (this.isShow) {
                this.goodsId = '';
                this.isShow = false;
                this.goodsInfo = {};
                this.capturedImage = null;
                this.$message({
                    showClose: true,
                    message: '请重新扫码',
                    type: 'warning'
                })
            } else {
                await this.$http.post('index/getGoodsDetail', obj, (res) => {
                    if (res.code == 1) {
                        this.goodsInfo = res.data;
                        this.codeNum = res.data.group_no;
                        console.log(res.data.order_id,'订单ID');
                        this.searchOrderTome(res.data.order_id)
                        this.generateBarcode();
                    } else {
                        if (res.msg == '订单状态错误') {
                            this.$message({
                                showClose: true,
                                message: '订单状态错误，请扫描其他订单',
                                type: 'warning'
                            }),
                                this.isShow = false
                        }
                    }
                })
                this.isShow = true;
            }
        },
        //点击打印贴条回调
        toPrintStick() {
            this.captrueHtml();
            setTimeout(() => {
                const printWindow = window.open('', '_blank');
                printWindow.document.open();
                printWindow.document.write('<html><head><title>Print</title></head><body>');
                printWindow.document.write('<div>' + this.$refs.printStick.innerHTML + '</div>');
                printWindow.document.write('</body></html>');
                printWindow.document.close();
                printWindow.focus();
                printWindow.print();
            }, 500);
            let obj = {
                orderNo:this.goodsInfo.number,
                barcode:this.goodsId,
                status:3,
                uid:JSON.parse(localStorage.getItem('userinfo')).id
            }
            this.$http.post('clothes/updateStatus',obj,(res => {
                console.log(res);
            }))
        },
        // 生成条码回调
        generateBarcode() {
            const barcodeData = this.goodsInfo.group_no; // 条码内容(衣物条形码也就是商品id)
            const canvas = this.$refs.barcodeCanvas;
            JsBarcode(canvas, barcodeData, {
                format: 'CODE128',
                lineColor: '#000000',
                width: 1,
                height: 25,
                displayValue: false,
                margin: 0,
                fontSize: 16
            });
            this.imgUrl = canvas.toDataURL('image/png');
        },
    },
};
</script>

<style lang="scss" scoped>
.big {
    width: 100%;
    height: 900px;
    background-color: white;
    display: flex;

    .left {
        width: 410px;
        height: 900px;
        border-right: 1px solid #eee;

        // background-color: aqua;
        .top {
            width: 100%;
            height: 100px;
            // background-color: aqua;
            padding: 10px;

            p {
                font-size: 20px;

            }

            input {
                width: 400px;
                height: 30px;
                margin-left: -5px;
            }
        }

        .content {
            height: 450px;
            // box-sizing: border-box;
            // border: 1px solid #eee;
            background-color: #fff;
            padding: 20px;
            

            p {
                color: rgb(147, 243, 147);
                font-size: 22px;
            }

            .content-line {
                margin: 10px 0;
                width: 370px;
                border: 1px solid #eee;
            }

            ul {
                li {
                    margin-top: 20px;
                    color: black;

                    span {
                        padding-left: 15px;
                        color: #706969;
                        font-size: 16px
                    }
                }
            }
        }
        .timeOut{
            background-image: url(../../assets/images/timeOut.png);
            background-repeat: no-repeat;
            background-position: 200px 110px;
            background-size: 140px 140px;
        }

        .bottom {
            .btn {
                margin-left: 150px;
            }
        }
    }

    .right {
        width: 100%;
        height: 100%;

        .right-top {
            margin-top: 20px;
            margin-left: 45%;
        }

        .right-content {
            width: 200px;
            height: 100px;
        }
    }
}

.empty {
    width: 100%;
    height: 900px;
    background: white;
    overflow: hidden;

    .empty-content {
        margin-top: 200px;
    }
}
</style>
